<template>
  <div id="about">
    <div class="header flex-between-center">
      <div class="logo flex">
        <img src="@/assets/about/logo.png" alt="logo" />
        <h1>星云文档</h1>
      </div>
      <div class="menu flex">
        <div
          :class="['menu-item', { actived: routePath.includes(item.path) }]"
          v-for="item in menu"
          :key="item.path"
          @click="handleMenu(item)"
        >
          {{ item.title }}
        </div>
        <a-popover>
          <template slot="content">
            <img
              class="app-qr-code"
              src="@/assets/about/app-qr-code.jpg"
              alt="app-qr-code"
            />
          </template>
          <div class="menu-item app-qr">星云小程序</div>
        </a-popover>
        <div class="star-app" @click="handleClick">进入星云网页版</div>
      </div>
    </div>

    <router-view class="about-content"></router-view>

    <div class="footer flex-column">
      <div class="contact-us-wrap flex">
        <div class="contact-us flex">
          <div
            class="contact-us-item"
            v-for="item in contactUs"
            :key="item.title"
          >
            <h4>{{ item.title }}</h4>
            <ul class="menu-wrap">
              <li
                class="contact-us-menu"
                v-for="menu in item.menuList"
                :key="menu.title"
              >
                <a class="link" :href="menu.link">{{ menu.title }}</a>
              </li>
            </ul>
          </div>
          <div class="contact-us-item">
            <h4>联系我们</h4>
            <p>联系电话：</p>
            <p>010-65880766</p>
            <p>邮箱地址：</p>
            <p>md@csii.com.cn</p>
          </div>
        </div>
        <div class="qr-code">
          <h4>关注我们</h4>
          <div class="flex">
            <div class="qr-code-item flex-column">
              <img src="@/assets/about/csii-qr-code.png" alt="csii-qr-code" />
              <span>公众号</span>
            </div>
            <div class="qr-code-item flex-column" style="margin-left: 40px">
              <img src="@/assets/about/app-qr-code.jpg" alt="app-qr-code" />
              <span>星云小程序</span>
            </div>
          </div>
        </div>
      </div>
      <div class="copyright flex">
        <img src="@/assets/about/csii.svg" alt="csii" />
        <span
          >©北京科蓝软件系统股份有限公司 | 版权所有 | 京ICP备15057782号</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import contactUs from "./json/contact-us.js";

export default {
  name: "App",
  components: {},
  data() {
    return {
      contactUs,
      menu: [
        {
          title: "首页",
          path: "/about/home",
        },
        {
          title: "应用场景",
          path: "/about/scenario",
        },
        {
          title: "私有化部署",
          path: "/about/deploy",
        },
      ],
    };
  },
  computed: {
    routePath() {
      return this.$route.path;
    },
  },
  methods: {
    handleMenu(value) {
      if (value.title === "星云小程序") {
      } else {
        this.$router.push({ path: value.path });
      }
    },
    handleClick() {
      this.$router.push({ path: "/star" });
      //   window.open("https://star.csii.com.cn");
    },
  },
};
</script>

<style lang="less">
.about-content {
  text-align: left;
  p {
    margin-bottom: 0px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0px;
  }
}
#about {
  height: 100%;
  min-height: 100vh;

  .default-width {
    width: 1200px;
    margin: 0 auto;
  }

  .app-qr-code {
    width: 120px;
    height: 120px;
  }

  .header {
    .default-width();
    height: 60px;

    .logo {
      img {
        width: 44px;
        height: 32px;
      }
      h1 {
        margin-left: 14px;
        line-height: 32px;
        font-size: 20px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #333333;
      }
    }

    .menu {
      align-content: center;
      .menu-item {
        height: 32px;
        line-height: 32px;
        cursor: pointer;
        font-size: 16px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 600;
        color: #333333;
        margin-left: 60px;
      }
      .actived {
        color: #4a61ff;
      }
      .star-app {
        width: 130px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        border-radius: 6px;
        border: 1px solid #4a61ff;
        color: #4a61ff;
        margin-left: 60px;
        cursor: pointer;
      }
      .star-app:hover {
        opacity: 0.85;
      }
      .star-app:active {
        opacity: 0.7;
      }
      .app-qr {
        cursor: default;
      }
    }
  }

  .footer {
    height: 374px;
    background-color: #282f4b;
    padding-top: 60px;
    padding-bottom: 40px;
    justify-content: space-between;
    text-align: left;
    p {
      margin-bottom: 0px;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 0px;
    }
    .contact-us-wrap {
      .default-width();
      justify-content: space-between;
      h4 {
        font-size: 16px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 600;
        color: #ffffff;
        opacity: 0.9;
      }
      p,
      span,
      .link {
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #ffffff;
        text-decoration: none;

        opacity: 0.6;
      }
      .contact-us {
        .contact-us-item {
          width: 138px;
          margin-right: 30px;
          h4 {
            margin-bottom: 32px;
          }
          .menu-wrap {
            .contact-us-menu {
              margin-bottom: 16px;
            }
          }
          p:nth-child(2n + 1) {
            margin-bottom: 20px;
          }
          p:nth-child(2n) {
            margin-bottom: 12px;
          }
        }
      }
      .qr-code {
        h4 {
          margin-bottom: 24px;
        }
        .qr-code-item {
          width: 104px;
          align-items: center;
          img {
            width: 104px;
            height: 104px;
            margin-bottom: 16px;
          }
        }
      }
    }
    .copyright {
      .default-width();
      justify-content: space-between;
      align-items: flex-end;
      img {
        height: 40px;
      }
      span {
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
}
</style>